export const normalizeValue = (value) => {
  if (/^\d+$/.test(value)) {
    return parseInt(value, 10);
  }

  if (/^[+-]?\d+(\.\d+)?$/.test(value)) {
    return parseFloat(value, 10);
  }

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

export const getObjectValueFromQuery = (query, name) => {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(query)) {
    if (key.startsWith(name)) {
      result[key] = normalizeValue(value);
    }
  }
  return result;
};

export const clearQueryKeys = (query, name) => {
  const newQuery = { ...query };
  // clear the query of "val" object keys, cause the "val" object entries may vary
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(query)) {
    if (key.startsWith(name)) delete newQuery[key];
  }
  return newQuery;
};
