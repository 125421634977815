<template>
  <component :is="component" v-model="val" v-bind="attrs" outlined dense hide-details />
</template>

<script>
import isequal from 'lodash.isequal';
import { VTextField, VSelect, VAutocomplete, VCheckbox } from 'vuetify/lib';
import { mapState } from 'vuex';
import { normalizeValue, getObjectValueFromQuery, clearQueryKeys } from '@/helpers/filters';

export default {
  components: {
    VTextField,
    VSelect,
    VAutocomplete,
    VCheckbox,
  },
  props: {
    component: { type: [Object, String], required: true },
    name: { type: String, required: true },
    attrs: { type: Object, default: null },
  },
  computed: {
    ...mapState('inventory_items', ['product']),
    query() {
      return this.$route.query;
    },
    val: {
      get() {
        if (this.name === 'warehouse_total_stock') {
          return getObjectValueFromQuery(this.query, 'warehouse_total_stock');
        }
        return normalizeValue(this.query[this.name]);
      },
      set(val) {
        let query = {};

        if (typeof val === 'object') {
          query = { ...clearQueryKeys(this.query, this.name), ...val };
        } else if (this.name === 'gmid') {
          query = { ...this.query, [this.name]: val };
          query = this.handleGmidFilter(query);
        } else {
          query = { ...this.query, [this.name]: val };
        }

        if ([null, undefined, ''].includes(val)) {
          query = clearQueryKeys(query, this.name);
        }
        delete query.page;

        if (!isequal(query, this.query)) this.$router.replace({ query });
      },
    },
  },
  watch: {
    attrs: {
      immediate: true,
      async handler(attrs) {
        if (this.name === 'warehouse_total_stock') {
          await this.$nextTick();
          if (!attrs.items.find((item) => isequal(item.value, this.val))) {
            const newQuery = clearQueryKeys(this.$route.query, this.name);
            if (!isequal(newQuery, this.query)) this.$router.replace({ query: newQuery });
          }
        }
      },
    },
  },
  methods: {
    handleGmidFilter(query) {
      const newQuery = { ...query };
      if (newQuery.gmid && this.product.gmid) {
        newQuery.gmid = this.product.gmid;
        delete newQuery.search;
      } else {
        delete newQuery.gmid;
        newQuery.search = this.product.name;
      }

      return newQuery;
    },
  },
};
</script>
